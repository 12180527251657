import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmDialog from './confirm-dialog';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectModal from 'Webapp/shared/app/connectors/connectModal';

function ConfirmDismiss({
  name,
  title,
  message,
  dismissAllModals,
  onConfirm,
  t,
  ...props
}) {
  return (
    <ConfirmDialog
      name={`${name}-confirm-dismiss`}
      title={title || t('unsaved_changes')}
      message={
        message || t('default_unsaved_changes_confirm_close_modal_message')
      }
      onConfirm={onConfirm || dismissAllModals}
      {...props}
    />
  );
}

ConfirmDismiss.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func,
  dismissAllModals: PropTypes.func,
  t: PropTypes.func.isRequired,
};

ConfirmDismiss.defaultProps = {
  message: null,
  title: null,
  dismissAllOnConfirm: true,
  onConfirm: null,
};

export default connector(connectModal)(withT(ConfirmDismiss));
