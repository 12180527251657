import React from 'react';
import PropTypes from 'prop-types';
import ImageSelectorGridItem from './image-selector-grid-item';

function ImageSelectorGrid({ items }) {
  return (
    <ul className="image-selector-grid">
      {items.map((item) => (
        <ImageSelectorGridItem {...item} key={item.id} />
      ))}
    </ul>
  );
}

ImageSelectorGrid.propTypes = {
  items: PropTypes.array.isRequired,
};

ImageSelectorGrid.defaultProps = {
  selectedImage: null,
};

export default ImageSelectorGrid;
