import React from 'react';
import PropTypes from 'prop-types';
import SelectCheckmarkIcon from 'ComponentLibrary/icons/select-checkmark';
import Image from './base/image';

function ImageSelectorGridItem({ image, isSelected, onClick }) {
  let sizeLabel;
  if (image.original_height && image.original_width) {
    sizeLabel = `${image.original_height} x ${image.original_width}`;
  }
  return (
    <li
      onClick={onClick}
      className={`image-selector-grid__item ${
        isSelected ? 'image-selector-grid__item--selected' : ''
      }`}
    >
      <div className="image-selector-grid__item-wrapper">
        <Image
          className="image-selector-grid__item-image"
          image={image}
          alt="image-selector-grid-item"
          crop
        />
        <div className="image-selector-grid__item-overlay" />
        <div className="image-selector-grid__item-details">
          <span className="image-selector-grid__size">{sizeLabel}</span>
          <SelectCheckmarkIcon
            backgroundColor="#fff"
            checkmarkColor="#fff"
            isSelected={isSelected}
            className="image-selector-grid__item-checkmark"
          />
        </div>
      </div>
    </li>
  );
}

ImageSelectorGridItem.propTypes = {
  image: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  selectedImage: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ImageSelectorGridItem.defaultProps = {
  selectedImage: null,
};

export default ImageSelectorGridItem;
