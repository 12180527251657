import { generateGAButtonName } from 'Utils/analytics/ga/buttons';

const generateCuratorProButtonName = (...args) =>
  generateGAButtonName('webapp', 'curator-pro', ...args);

const generateCuratorProStoryboardButtonName = (...args) =>
  generateCuratorProButtonName('storyboard', ...args);

const generateCuratorProMagazineButtonName = (...args) =>
  generateCuratorProButtonName('magazine', ...args);

const generateCuratorProCampaignButtonName = (...args) =>
  generateCuratorProButtonName('campaign', ...args);

export {
  generateCuratorProStoryboardButtonName,
  generateCuratorProMagazineButtonName,
  generateCuratorProCampaignButtonName,
};
