import React from 'react';
import PropTypes from 'prop-types';

const RATIO = 46 / 56;

function QuoteIcon({ size, color, className }) {
  const height = RATIO * size;
  return (
    <svg
      role="img"
      aria-labelledby="quote-icon"
      className={`quote-icon ${className}`}
      width={size}
      height={height}
      viewBox="0 0 56 46"
    >
      <title>Quote Symbol</title>
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons-/-Quote" fill={color}>
          <path
            d="M21.3,45.75 L9.59232693e-14,45.75 L9.59232693e-14,25.35 C9.59232693e-14,12.75 5.25,6.3 17.4,3.55271368e-15 L22.95,9.75 C15,13.65 12.75,17.7 12.75,25.05 L21.3,25.05 L21.3,45.75 Z M54.15,45.75 L32.7,45.75 L32.7,25.35 C32.7,12.75 38.1,6.3 50.25,3.55271368e-15 L55.8,9.75 C47.85,13.65 45.45,17.7 45.45,25.05 L54.15,25.05 L54.15,45.75 Z"
            id="“"
          />
        </g>
      </g>
    </svg>
  );
}

QuoteIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
};

QuoteIcon.defaultProps = {
  size: 46,
  color: '#E12828',
  className: '',
};

export default QuoteIcon;
