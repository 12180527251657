import { Connector } from 'Webapp/utils/connector';

import {
  getBasicSections,
  getBasicSection,
  getBasicSectionWithItems,
} from 'Webapp/shared/app/redux/actions/section-actions';

const actions = { getBasicSections, getBasicSection, getBasicSectionWithItems };

export type ConnectBasicSectionProps = Flipboard.ConnectorProps<
  unknown,
  typeof actions
>;

const connectBasicSection: Connector = {
  actions,
};

export default connectBasicSection;
