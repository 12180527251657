import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BadgeIcon from 'ComponentLibrary/icons/badge';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { BODY_TYPES } from 'Style/typography';

const BadgeWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isOverlay',
})({
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
});

const BadgeLabel = styled.div(BODY_TYPES.SMALL_STANDARD, {
  paddingLeft: SPACING.SMALL,
  color: 'inherit',
});
const Badge = ({ className, isOverlay, children }) => (
  <BadgeWrapper
    className={classNames(className, { 'app__badge-wrapper': true })}
  >
    <BadgeIcon isOverlay={isOverlay} />
    {children && <BadgeLabel className="badge-label">{children}</BadgeLabel>}
  </BadgeWrapper>
);

Badge.propTypes = {
  className: PropTypes.string,
  isOverlay: PropTypes.bool,
  badgeLabel: PropTypes.string,
  children: PropTypes.node,
};

Badge.defaultProps = { isOverlay: false, badgeLabel: null };

export default Badge;
