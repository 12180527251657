import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';

// Utils
import FlapUtil from 'Utils/content/flap-util';
import { generateCuratorProStoryboardButtonName } from '../../../../client/ga/buttons';

// Components
import Input from 'Webapp/shared/app/components/base/input';
import InputLimit from 'ComponentLibrary/forms/input-limit';
import LabelWithButton from 'Webapp/shared/app/components/label-with-button';
import Label from 'ComponentLibrary/forms/label';
import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';

import connectFeatureFlags from 'Webapp/shared/app/connectors/connectFeatureFlags';
import connectBasicSection from 'Webapp/shared/app/connectors/connectBasicSection';

const TitleInputLimit = styled(InputLimit)({ marginBottom: SPACING.SMALL });
class ArticleItemOverride extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sectionItem: null,
    };
  }

  async componentDidMount() {
    const { url, getBasicSectionWithItems } = this.props;
    const remoteid = FlapUtil.getResolveUrlRemoteid(url);
    const section = await getBasicSectionWithItems(remoteid);

    /**
     * TODO: This is some fake "section" used to retrieve the article. We should/could proably
     * obscure this and replace it with something clearer like a "getCuratorProArticle" method
     * or something. Getting `section.items[0]` is a bit of a hack.
     */

    if (section) {
      if (section.items && section.items.length === 1) {
        this.setState({ sectionItem: section.items[0] });
      }
    }
  }

  componentDidUpdate(_, prevState) {
    const { sectionItem } = this.state;
    if (!this.props.excerptText && !prevState.sectionItem && sectionItem) {
      this.props.onSet({ excerptText: sectionItem.excerpt });
    }
  }

  handleClickReset(name) {
    let value;
    if (name === 'title') {
      value = this.state.sectionItem && this.state.sectionItem.title;
    } else if (name === 'excerptText') {
      value = this.state.sectionItem && this.state.sectionItem.excerpt;
    }
    this.props.onSet({ [name]: value || '' });
  }

  render() {
    const {
      title,
      excerptText,
      clickUrl,
      featureFlags,
      maxTitleLength,
      onChange,
      t,
    } = this.props;
    const { sectionItem } = this.state;

    return (
      <React.Fragment>
        <div className="field-block">
          <LabelWithButton
            labelText={t('title')}
            buttonLabel={t('reset_override_title')}
            buttonName={generateCuratorProStoryboardButtonName(
              'edit-article',
              'reset-title',
            )}
            isButtonVisible={
              !!(title && title !== (sectionItem && sectionItem.title))
            }
            onButtonClick={() => this.handleClickReset('title')}
          >
            {sectionItem && sectionItem.title !== title && (
              <TitleInputLimit
                currentCount={title.length}
                limit={maxTitleLength}
              />
            )}
            <Input
              name="title"
              value={title}
              placeholder={sectionItem ? sectionItem.title : ''}
              onChange={onChange}
            />
          </LabelWithButton>
        </div>
        {featureFlags.INTERNAL_PACKAGE_MANAGEMENT && (
          <div className="field-block">
            <Label text={t('storyboard_item_click_url')}>
              <Input
                name="clickUrl"
                type="url"
                value={clickUrl}
                onChange={onChange}
              />
            </Label>
          </div>
        )}
        <div className="field-block">
          <LabelWithButton
            labelText={t('excerpt')}
            buttonLabel={t('reset_override_excerpt')}
            buttonName={generateCuratorProStoryboardButtonName(
              'edit-article',
              'reset-excerpt',
            )}
            isButtonVisible={
              !!(
                sectionItem &&
                excerptText &&
                sectionItem.excerpt !== excerptText
              )
            }
            onButtonClick={() => this.handleClickReset('excerptText')}
          >
            <textarea
              className="textarea--medium"
              name="excerptText"
              value={excerptText || ''}
              placeholder={sectionItem ? sectionItem.excerpt : ''}
              onChange={onChange}
            />
          </LabelWithButton>
        </div>
      </React.Fragment>
    );
  }
}

ArticleItemOverride.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  excerptText: PropTypes.string,
  clickUrl: PropTypes.string,
  uid: PropTypes.number.isRequired,
  maxTitleLength: PropTypes.number.isRequired,
  featureFlags: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSet: PropTypes.func.isRequired,
  getBasicSectionWithItems: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

ArticleItemOverride.defaultProps = {
  clickUrl: '',
  excerptText: '',
};

export default connector(
  connectFeatureFlags,
  connectBasicSection,
)(withT(ArticleItemOverride));
