import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function Message({ type, children, className }) {
  if (!children) {
    return null;
  }
  const classNames = classnames(className, {
    message: true,
    'message--error': type === 'error',
    'message--success': type === 'success',
  });
  return <div className={classNames}>{children}</div>;
}

Message.types = {
  SUCCESS: 'success',
  ERROR: 'error',
};

Message.propTypes = {
  type: PropTypes.oneOf(Object.values(Message.types)),
  children: PropTypes.node,
  className: PropTypes.string,
};

Message.defaultProps = {
  type: Message.types.ERROR,
  children: null,
  className: null,
};

export default Message;
