import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';

const InputLimitText = styled.div(
  {
    position: 'absolute',
    top: `calc(-1 * ${SPACING.LARGE})`,
  },
  UI_TEXT_TYPES.SUPPORTING,
);

function InputLimit({ currentCount, limit }) {
  return (
    <InputLimitText>
      {currentCount}/{limit}
    </InputLimitText>
  );
}

InputLimit.propTypes = {
  currentCount: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

export default InputLimit;
