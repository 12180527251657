import {
  updateMagazineCover,
  customizeItem,
  deleteItem,
  moveItem,
  loadNextItemsPage,
  updateMagazine,
  deleteMagazine,
  flipItem,
} from 'Webapp/shared/app/redux/actions/curator-pro/magazine-actions';
import {
  usageTrackCuratorProToggleItemsView,
  usageTrackMagazineInviteContributor,
} from 'Webapp/shared/app/redux/actions/usage-actions';

import { fetchUrlImages } from 'Webapp/shared/app/redux/actions/compose-actions';

export default {
  selectors: {
    magazine: ({ curatorProMagazine }) => curatorProMagazine.magazine,
    items: ({ curatorProMagazine }) => curatorProMagazine.items,
    contributors: ({ curatorProMagazine }) => curatorProMagazine.contributors,
    isLoading: ({ curatorProMagazine }) => curatorProMagazine.isLoading,
    nextPageKey: ({ curatorProMagazine }) => curatorProMagazine.nextPageKey,
    neverLoadMore: ({ curatorProMagazine }) => curatorProMagazine.neverLoadMore,
    pipe: ({ curatorProMagazine }) =>
      (curatorProMagazine.pipes &&
        curatorProMagazine.pipes.length > 0 &&
        curatorProMagazine.pipes[0]) ||
      null,
  },
  actions: {
    customizeItem,
    deleteItem,
    deleteMagazine,
    updateMagazine,
    updateMagazineCover,
    moveItem,
    loadNextItemsPage,
    usageTrackCuratorProToggleItemsView,
    usageTrackMagazineInviteContributor,
    fetchUrlImages,
    flipItem,
  },
};
