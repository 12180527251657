import React from 'react';
import PropTypes from 'prop-types';

import { largestUrl, isMatch } from 'Utils/image-util';
import ImageSelectorGrid from '../image-selector-grid';

function StoryboardImageSelector({ images, selectedImage, onSelect }) {
  return (
    <ImageSelectorGrid
      items={images.map((image) => {
        const url = largestUrl(image);
        return {
          key: url,
          image,
          id: url,
          isSelected: isMatch(selectedImage, image),
          onClick: () => onSelect(url, image.sourceDomain),
        };
      })}
    />
  );
}

StoryboardImageSelector.propTypes = {
  images: PropTypes.array.isRequired,
  selectedImage: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

StoryboardImageSelector.defaultProps = {
  selectedImage: null,
};

export default StoryboardImageSelector;
