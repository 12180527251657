import React from 'react';
import Label from 'ComponentLibrary/forms/label';
import Button from 'Webapp/shared/app/components/button';

interface LabelWithButtonProps {
  labelText: string;
  buttonLabel: string;
  buttonName: string;
  isButtonVisible: boolean;
  onButtonClick: (e: React.SyntheticEvent) => void;
  children: React.ReactNodeArray;
}

const LabelWithButton: React.FC<LabelWithButtonProps> = ({
  labelText,
  buttonLabel,
  buttonName,
  isButtonVisible,
  onButtonClick,
  children,
}) => (
  <Label
    /* Prevents label clicks from triggering nested button */
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
    labelComponent={
      <div className="field-block__label label-with-button__wrapper">
        <span className="ui-text--supporting label-with-button__text">
          {labelText}
        </span>
        {isButtonVisible !== false && (
          <Button
            name={buttonName}
            className="label-with-button__button"
            type="button"
            onClick={onButtonClick}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    }
  >
    {children}
  </Label>
);

export default LabelWithButton;
