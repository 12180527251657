import { AxiosResponse } from 'axios';
import { projection } from 'Webapp/utils/content/item-util';
import { UploadImageError, UrlPreviewError } from 'Webapp/utils/errors';

interface GetFlipContentFromUrlParams {
  url: Flipboard.URL;
  userid?: Flipboard.UserId;
  allImages?: 1;
}

const getFlipContentFromUrl =
  (
    params: GetFlipContentFromUrlParams,
  ): Flipboard.Thunk<Promise<Flipboard.FlapFlipusFlipData>> =>
  async (_dispatch, _getState, { flap }) => {
    try {
      const { data } = await flap.get<Flipboard.FlapFlipusResponse>(
        '/content/flipus',
        {
          params,
        },
      );

      const resolvedUrl = data.flus.url;
      const flipData = data.flip[resolvedUrl];
      if (!flipData) {
        throw new UrlPreviewError();
      }
      return flipData;
    } catch (e) {
      throw new UrlPreviewError();
    }
  };

export const fetchUrlImages =
  (
    url: Flipboard.URL,
    userid: Flipboard.UserId,
  ): Flipboard.Thunk<Promise<Flipboard.FlapImage[]>> =>
  async (dispatch, _getState) => {
    const params: GetFlipContentFromUrlParams = {
      allImages: 1,
      userid,
      url,
    };
    const flipData = await dispatch(getFlipContentFromUrl(params));
    if (!flipData) {
      throw new UrlPreviewError();
    }
    return flipData.images;
  };

const getFlipusDateCreated = (
  data: Flipboard.FlapFlipusFlipData,
): Flipboard.Item['dateCreated'] => {
  // this whole song and dance is to convert the flipus date to the flap date
  let dateCreated: number | undefined;
  try {
    dateCreated = new Date(data.articleDate.$date).getTime() / 1000;
    if (isNaN(dateCreated)) {
      throw new Error();
    }
  } catch {
    dateCreated = undefined;
  }
  return dateCreated;
};

export const fetchUrlPreview =
  (url: Flipboard.URL): Flipboard.Thunk<Promise<Flipboard.Item>> =>
  async (dispatch, _getState) => {
    const params = {
      url,
    };
    const flipData = await dispatch(getFlipContentFromUrl(params));
    if (!flipData) {
      throw new UrlPreviewError();
    }
    const item: Flipboard.Item = projection({
      dateCreated: getFlipusDateCreated(flipData),
      type: flipData.type,
      title: flipData.title,
      text: flipData.shortExcerpt,
      sourceDomain: flipData.domain,
      authorDisplayName: flipData.author,
      image: flipData.images?.[0],
    });
    return item;
  };

export const uploadImage =
  (image: File): Flipboard.Thunk<Promise<Flipboard.URL>> =>
  async (_dispatch, _, { flap }) => {
    const form = new FormData();

    form.append('file', image);

    const {
      data: { success, result },
    } = await flap.post<
      FormData,
      AxiosResponse<Flipboard.FlapResponse<{ result: Flipboard.URL }>>
    >('/flipboard/uploadImage', form, {
      params: {
        type: 'web-flip',
      },
    });

    if (success) {
      return result;
    }
    throw new UploadImageError();
  };
