import logger from 'Utils/logger';

const BUTTON_NAME_PART_REGEX = /^[a-z-\d]+$/;
function isValidButtonPartFormat(part = '') {
  return BUTTON_NAME_PART_REGEX.test(part);
}

/**
 * Generates button names for GA buttons
 * @param {String} appName - Name of App (eg. webapp, auth)
 * @param {Array} args - arguments to build a button name with.
 * Each argument must only include lower case characters, numbers and -.
 * At least 4 arguments are required, usually: app name, module name, feature name and button name.
 * Additional args can be provided for scoping even further, like sub-modules and sub-features
 * A dynamic number of arguments is allowed, as sometimes we want to be granular in scoping these names
 * @returns {String} GA button name
 * eg.
 * webapp.curator-pro.storyboard.create-modal.submit
 * auth.login.form.forgot-password
 */
function generateGAButtonName(...args) {
  if (args.length < 4) {
    logger.warn(
      'Not enough arguments supplied to generateGAButtonName. Ensure that at least app name, module, feature and button name are specified',
    );
    return;
  }
  if (!args.every(isValidButtonPartFormat)) {
    logger.error('Invalid button name format');
    return;
  }

  return args.join('.');
}

export { isValidButtonPartFormat, generateGAButtonName };
