import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
// Components
import CheckmarkIcon from './checkmark';
import withT from 'ComponentLibrary/hocs/withT';

const StyledSelectCharkmarkIcon = styled.div({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  height: 'auto',
  padding: SPACING.SMALL,
  borderRadius: '50%',
});

function SelectCheckmarkIcon({
  innerSize,
  backgroundColor,
  checkmarkColor,
  isSelected,
  toolTip,
  t,
}) {
  return (
    <StyledSelectCharkmarkIcon
      style={{
        backgroundColor: isSelected ? backgroundColor : 'transparent',
        border: `2px solid ${backgroundColor}`,
      }}
      role="img"
      aria-labelledby="select-checkmark-icon-title"
    >
      <title id="select-checkmark-icon-title">{toolTip || t('verified')}</title>
      <CheckmarkIcon
        color={isSelected ? '#000' : checkmarkColor}
        size={innerSize}
      />
    </StyledSelectCharkmarkIcon>
  );
}

SelectCheckmarkIcon.propTypes = {
  className: PropTypes.string,
  toolTip: PropTypes.string,
  backgroundColor: PropTypes.string,
  checkmarkColor: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  innerSize: PropTypes.number,
  t: PropTypes.func.isRequired,
};
SelectCheckmarkIcon.defaultProps = {
  backgroundColor: 'transparent',
  checkmarkColor: '#fff',
  isSelected: false,
  innerSize: 15,
};

export default withT(SelectCheckmarkIcon);
