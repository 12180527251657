import React from 'react';
import PropTypes from 'prop-types';
// import classNames from 'classnames';

function BadgeIcon({ isOverlay }) {
  // const className = classNames('badge-icon', {});

  return isOverlay ? (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <rect x="4" y="10.5" width="3.25" height="3.25" fill="#F52828" />
        <rect x="4" y="4" width="3.25" height="3.25" fill="#F52828" />
        <rect x="7.25" y="4" width="3.25" height="3.25" fill="#F52828" />
        <rect x="10.5" y="4" width="3.25" height="3.25" fill="#F52828" />
        <rect x="4" y="7.25" width="3.25" height="3.25" fill="#F52828" />
        <rect x="13.75" y="10.5" width="3.25" height="3.25" fill="#F52828" />
        <rect x="13.75" y="7.25" width="3.25" height="3.25" fill="#F52828" />
        <rect x="13.75" y="13.75" width="3.25" height="3.25" fill="#F52828" />
        <rect x="10.5" y="13.75" width="3.25" height="3.25" fill="#F52828" />
        <rect x="7.25" y="13.75" width="3.25" height="3.25" fill="#F52828" />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0"
          y="0"
          width="21"
          height="21"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="6.5" width="3.25" height="3.25" fill="#F52828" />
      <rect width="3.25" height="3.25" fill="#F52828" />
      <rect x="3.25" width="3.25" height="3.25" fill="#F52828" />
      <rect x="6.5" width="3.25" height="3.25" fill="#F52828" />
      <rect y="3.25" width="3.25" height="3.25" fill="#F52828" />
      <rect x="9.75" y="6.5" width="3.25" height="3.25" fill="#F52828" />
      <rect x="9.75" y="3.25" width="3.25" height="3.25" fill="#F52828" />
      <rect x="9.75" y="9.75" width="3.25" height="3.25" fill="#F52828" />
      <rect x="6.5" y="9.75" width="3.25" height="3.25" fill="#F52828" />
      <rect x="3.25" y="9.75" width="3.25" height="3.25" fill="#F52828" />
    </svg>
  );
}

BadgeIcon.propTypes = { isOverlay: PropTypes.bool };

BadgeIcon.defaultProps = { isOverlay: false };

export default BadgeIcon;
